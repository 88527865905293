'use client';

import {
  CardItemLiveEntityMobile,
  CardLiveFeedEntityMobile,
} from '@entities/mobile/cards';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';

import { mockedData } from '@/mocks/items';
import { WithCurrency } from '@/shared/hocs';
import { GlobalItem } from '@/shared/types/common';
import { Illustration, Label } from '@/shared/ui';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';

import styles from './live-feed.module.scss';

export const LiveFeedWidgetMobile = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [sheetOpened, setSheetOpened] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedItem, setSelectedItem] = useState<GlobalItem | null>(null);

  const toggleSheet = (item: GlobalItem | null) => {
    setSheetOpened(prev => !prev);
    setSelectedItem(item);
  };

  const itemprice = 3;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();
  const items = mockedData;

  return (
    <div className={styles['content-widget__live-feed-container']}>
      <Label
        className={styles['label']}
        labelVariant={'purple'}
        icon={'graph-new-up'}
        variant={'primary'}
      >
        Live Sales
      </Label>
      <div className={styles['content-widget__live-feed']}>
        {Array.isArray(items) &&
          items.length > 0 &&
          items?.map((item, idx) => (
            <CardLiveFeedEntityMobile
              handleInfoClick={() => toggleSheet(item)}
              key={`${idx}-${item.info.title}-${item?.info?.price?.old}`}
              item={item.item}
              rarity={item.rarity}
            />
          ))}
      </div>

      <BottomSheetUi isOpen={sheetOpened} handleClose={() => toggleSheet(null)}>
        <BottomSheetUi.Content>
          <BottomSheetUi.Header>About purchase</BottomSheetUi.Header>

          <div className={styles['content-widget__live-feed-sheet-info']}>
            {selectedItem && (
              <CardItemLiveEntityMobile
                leftSlot={
                  <CardItemLiveEntityMobile.Item
                    item={selectedItem.item}
                    rarity={selectedItem.rarity}
                  />
                }
                middleSlot={
                  <CardItemLiveEntityMobile.ItemInfo
                    title={selectedItem.info.title}
                    age={selectedItem.info.age}
                  />
                }
                rightSlot={
                  <CardItemLiveEntityMobile.ItemProperties
                    tagCategory={selectedItem.tagCategory}
                    rarity={selectedItem.rarity}
                  />
                }
              />
            )}
            <AdditionalInfoMobile
              variant={'success'}
              icon={
                <Illustration
                  style={{ color: 'green' }}
                  name={'tag-price'}
                  spriteName={'icons'}
                  spanTagClassName={styles.icon}
                />
              }
            >
              Item price:{' '}
              <WithCurrency>{selectedItem?.info.price.current}</WithCurrency>
            </AdditionalInfoMobile>
          </div>
          <div className={styles['content-widget__live-feed-sheet-btns']}>
            <BottomSheetUi.Button
              onClick={
                selectedItem
                  ? () =>
                      router.push(
                        `/shop/${selectedItem?.category}/${selectedItem?.id}`,
                      )
                  : () => {}
              }
              text={'Go to item'}
              variant={'primary'}
              iconRight={
                <Illustration
                  id={'btn-icon'}
                  name={'square-arrow-right'}
                  spriteName={'icons'}
                />
              }
            />
            <BottomSheetUi.Button
              text={'Close'}
              variant={'secondary'}
              onClick={() => toggleSheet(null)}
            />
          </div>
        </BottomSheetUi.Content>
      </BottomSheetUi>
    </div>
  );
};
