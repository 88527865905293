'use client';
import { ChangeCurrencyFeature } from '@features/desktop/change-currency';
import { useScrollDirection } from '@hooks/client/useScrollDirection';
import { HeaderSearchInput } from '@widgets/mobile/header/ui/header-search';
import cn from 'clsx';
import React from 'react';

import { DropListGame } from '@/features/drop-list-game';
import { DropListBalance } from '@/shared/components/drop-list-balance';
import { Logo } from '@/shared/ui';

import styles from './header.module.scss';

export const HeaderWidgetMobile = () => {
  const direction = useScrollDirection();

  const headerClasses = cn(styles.container, {
    [styles['container-scroll-down']]: direction === 'down',
  });

  const bottomSlotClasses = cn(styles.bottom, {
    [styles['bottom-closed']]: direction === 'down',
  });

  return (
    <header className={headerClasses}>
      <div className={styles.content}>
        <div className={styles['top']}>
          <Logo />
          <div className={styles['top-right_slot']}>
            <DropListBalance isMobile />
            <ChangeCurrencyFeature isMobile />
            <DropListGame isMobile />
          </div>
        </div>
        <div className={bottomSlotClasses}>
          <HeaderSearchInput />
        </div>
      </div>
    </header>
  );
};
