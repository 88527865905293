import * as Yup from 'yup';

export const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Password must match')
    .required('Required'),
});
