import * as Yup from 'yup';

export const baseValidationSchema = Yup.object({
  userName: Yup.string()
    .min(3, 'Must be 3 characters or more')
    .max(24, 'Must be 24 characters or less')
    .matches(
      /^[a-zA-Z0-9._]+$/,
      'Nickname can only contain Latin letters, digits, ".", and "_"',
    )
    .test(
      'max-2-special-symbols',
      'Nickname can contain at most 2 special symbols (_ and .)',
      value => {
        if (!value) return true;
        const specialSymbolsRegex = /[_\.]/g;
        const specialSymbolsCount = (value.match(specialSymbolsRegex) || [])
          .length;

        return specialSymbolsCount <= 2;
      },
    )
    .required('Required field'),
  email: Yup.string()
    .test('nickname-or-email', 'Incorrect email', value => {
      if (!value) return true;

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (emailRegex.test(value)) {
        return true;
      }
    })
    .required('Required field'),
});

export const validationSchema = Yup.object({
  ...baseValidationSchema.fields,
  password: Yup.string()
    .min(6, 'Must be 6 characters or more')
    .max(32, 'Must be 32 characters or less')
    .required('Required field'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Password must match')
    .required('Required'),
});
