import styles from '@features/auth-modal-content/ui/styles.module.scss';
import { useFormik } from 'formik';
import React from 'react';

import {
  Button,
  Illustration,
  IllustrationModal,
  ModalContent,
} from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { useStore } from '@/store/context';

import { validationSchema } from './validation';
export const SetPasswordForm = () => {
  const auth = useStore().authorization;

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    validationSchema: validationSchema,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const onSubmit = () => {};

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => auth.closeModal()} />
      }
    >
      <IllustrationModal variant={'password-recovery'} />
      <ModalContent.Title
        title={'Password recovery'}
        subtitle={'Please set your password'}
      />

      <ModalContent.Body>
        <div className={styles['inputs-container']}>
          <InputAuth
            value={''}
            iconName={'user'}
            placeholder="Email"
            checked
            withErrorMessage={false}
          />
          <InputAuth
            iconName={'lock-password'}
            value={formik.values.password}
            onChange={formik.handleChange('password')}
            onBlur={formik.handleBlur('password')}
            onInput={() => formik.setFieldTouched('password')}
            placeholder="Password"
            withErrorMessage={false}
            securityInput
            errorMessage={formik.touched.password ? formik.errors.password : ''}
          />
          <InputAuth
            iconName={'lock-password'}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange('confirmPassword')}
            onBlur={formik.handleBlur('confirmPassword')}
            onInput={() => formik.setFieldTouched('confirmPassword')}
            placeholder="Confirm password"
            withErrorMessage={false}
            securityInput
            errorMessage={
              formik.touched.confirmPassword
                ? formik.errors.confirmPassword
                : ''
            }
          />
        </div>
        <ModalContent.Footer>
          <Button
            onClick={onSubmit}
            variant="primary"
            text="Confirm"
            width="full-w"
            className={styles.button}
            disabled={!formik.isValid}
            iconLeft={
              <Illustration
                style={{ color: '#fff' }}
                name={'user-check-rounded'}
                spriteName={'icons'}
                size={22}
              />
            }
          />
        </ModalContent.Footer>
      </ModalContent.Body>
    </ModalContent>
  );
};
