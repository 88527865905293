'use client';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';

export const FooterWidgetMobileWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const pathname = usePathname();

  const hideFooter =
    pathname.includes('inventory') ||
    pathname.includes('not-found') ||
    pathname.includes('drop');

  if (hideFooter) return null;

  return children;
};
