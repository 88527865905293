'use client';
import { BannerImageEntityMobile } from '@entities/mobile/banner-image';
import { BannersVariants } from '@entities/mobile/banner-image/banner-image.types';
import { Autoplay, Pagination, Swiper, SwiperSlide } from '@widgets/swiper';
import cn from 'clsx';
import { useRouter } from 'next/navigation';
import React, { useEffect, useRef } from 'react';
import { useIsClient, useMediaQuery } from 'usehooks-ts';

import {
  BLOGGERS_COLLABORATION,
  CONSTANT_PROMOTION,
} from '@/shared/constants/links';
import { DivElementType } from '@/shared/types/common';
import { Plate } from '@/shared/ui/desktop/plate';

import styles from './carousel.module.scss';
import swiperStyles from './swiper.module.scss';

export type ContentBannerProps = DivElementType & {
  banners: BannerVariantWithAction[];
  screenSize: 'small' | 'large' | null;
  sliderSize: 'l' | 'm';
};

export type BannerVariantWithAction = {
  variant: BannersVariants;
  pressAction: () => void;
};

export const MainPromoTopBannersCarousel = () => {
  const topBannersEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToItems = () => {
    topBannersEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  useEffect(() => {
    const element = document.body.querySelector(
      '#scroll-anchor',
    ) as HTMLDivElement;

    if (element) {
      topBannersEndRef.current = element;
    }
  }, []);

  const router = useRouter();
  const isClient = useIsClient();

  const isMediumScreen = useMediaQuery('(min-width: 428px)');

  const screenSize = isClient ? (isMediumScreen ? 'large' : 'small') : null;

  const topBanners: BannerVariantWithAction[] = [
    {
      variant: 'pets-giveaway',
      pressAction: () => {},
    },
    {
      variant: 'concept1',
      pressAction: scrollToItems,
    },
    {
      variant: 'concept2',
      pressAction: () => router.push('/shop'),
    },
    {
      variant: 'concept3',
      pressAction: scrollToItems,
    },
  ];

  return (
    <CarouselBanner
      banners={topBanners}
      screenSize={screenSize}
      sliderSize={'l'}
    />
  );
};

export const MainPromoBottomBannersCarousel = () => {
  const router = useRouter();
  const isClient = useIsClient();

  const isMediumScreen = useMediaQuery('(min-width: 428px)');

  const screenSize = isClient ? (isMediumScreen ? 'large' : 'small') : null;

  const bottomBanners: BannerVariantWithAction[] = [
    {
      variant: 'bloggers-collaboration',
      pressAction: () => window.location.assign(BLOGGERS_COLLABORATION),
    },
    {
      variant: 'constant-promotion',
      pressAction: () => window.location.assign(CONSTANT_PROMOTION),
    },
    {
      variant: 'social-boost',
      pressAction: () => {},
    },
  ];

  return (
    <CarouselBanner
      banners={[...bottomBanners, ...bottomBanners]}
      screenSize={screenSize}
      sliderSize={'m'}
    />
  );
};

const CarouselBanner = ({
  className,
  sliderSize,
  banners,
  screenSize,
}: ContentBannerProps) => {
  const bannerClasses = cn(styles['content-widget__banner'], className);
  const bannerSlideClasses = cn(
    swiperStyles['slide'],
    swiperStyles[`slide-${sliderSize}`],
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLargeMobile = useMediaQuery('(min-width: 744px)');

  const swiperConfig = isLargeMobile
    ? {
        centeredSlides: true,
        slidesPerView: 1.7,
      }
    : {
        slidesPerView: 1,
      };

  if (!screenSize) {
    return (
      <div className={bannerClasses}>
        <Plate className={swiperStyles.plate} />
      </div>
    );
  }

  return (
    <div className={bannerClasses}>
      <Swiper
        pagination={{
          clickable: true,
        }}
        loop
        autoplay
        {...swiperConfig}
        modules={[Autoplay, Pagination]}
        className={'swiper-mobile'}
      >
        {banners.map((banner, idx) => {
          return (
            <SwiperSlide key={`${idx}-src-mobile-slide`}>
              <div className={bannerSlideClasses} onClick={banner.pressAction}>
                <BannerImageEntityMobile
                  variant={banner.variant}
                  size={screenSize}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
