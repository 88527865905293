'use client';
import { useEffect, useState } from 'react';

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('up');
  const [element, setElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const scrollElement = document.getElementById('mobile-main');
    setElement(scrollElement);
  }, []);

  useEffect(() => {
    if (!element) return;
    let lastScrollY = element?.scrollTop;
    const updateScrollDirection = () => {
      const scrollY = element?.scrollTop;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (direction === 'down' && scrollY < 100) return;
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 1 || scrollY - lastScrollY < -1)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    element.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      element.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection, element]);

  return scrollDirection;
};
