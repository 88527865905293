'use client';
import { CardMarketEntityMobile } from '@entities/mobile/cards';
import { observer } from 'mobx-react-lite';

import {
  mockedCategoriesMobile1,
  mockedMM2Categories,
} from '@/mocks/market-categories';
import { useStore } from '@/store/context';

import styles from './categories.module.scss';

export const CategoriesWidgetMobile = observer(
  ({
    categoryFrom = 0,
    categoryTo = 6,
  }: {
    categoryTo?: number;
    categoryFrom?: number;
  }) => {
    const selectedGame = useStore()?.app.selectedGame;
    const categories =
      selectedGame === 'adopt'
        ? mockedCategoriesMobile1
        : mockedMM2Categories.slice(categoryFrom, categoryTo);

    return (
      <div className={styles['content-widget__categories']}>
        {categories?.map(({ id, ...category }, idx) => {
          return (
            <CardMarketEntityMobile
              key={`mobile-${idx}-${category.title}-${category.item}`}
              {...category}
            />
          );
        })}
      </div>
    );
  },
);
