import * as Yup from 'yup';

export const validationSchema = Yup.object({
  oldPassword: Yup.string()
    .min(6, 'Must be at least 6 characters')
    .required('Required'),
  password: Yup.string()
    .min(6, 'Must be at least 6 characters')
    .notOneOf(
      [Yup.ref('oldPassword'), ''],
      'The old and new passwords are the same',
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Password must match')
    .required('Required'),
});
