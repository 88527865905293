import { deleteCookie, setCookie } from 'cookies-next';
import { makeAutoObservable } from 'mobx';

import { USER_INFO, VERIFY_SOCIAL_AUTH } from '@/shared/api/endpoints';
import usersApi from '@/shared/api/usersApi';
import {
  AuthResponse,
  ProfileResponse,
  UserInfo,
} from '@/store/authorization/types';

export const formsMap = {
  SIGN_IN: 'signIn',
  SIGN_UP: 'signUp',
  RECOVERY: 'recovery',
  SET_PASSWORD: 'setPassword',
  RESET: 'reset',
  PROMO_LANDING: 'promoLanding',
} as const;

type Keys = keyof typeof formsMap;
type AvailableForms = (typeof formsMap)[Keys] | null;

class AuthorizationStore {
  openedForm: AvailableForms = null;
  authRedirectPath: string | null = null;
  step: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setAuthRedirectPath(path: string | null) {
    this.authRedirectPath = path;
  }

  openPromoLanding() {
    this.step = 0;
    this.openedForm = formsMap.PROMO_LANDING;
  }

  openSignIn() {
    this.step = 0;
    this.openedForm = formsMap.SIGN_IN;
  }
  openSignUp() {
    this.step = 0;
    this.openedForm = formsMap.SIGN_UP;
  }

  openReset() {
    this.step = 0;
    this.openedForm = formsMap.RESET;
  }
  openRecovery() {
    this.step = 0;
    this.openedForm = formsMap.RECOVERY;
  }

  openSetPassword() {
    this.step = 0;
    this.openedForm = formsMap.SET_PASSWORD;
  }

  closeModal() {
    this.step = 0;
    this.openedForm = null;
    this.authRedirectPath = null;
  }

  setStep(value: number) {
    this.step = value;
  }
}

export const authorizationStore = new AuthorizationStore();
