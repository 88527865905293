import { CartBadgeItemsCounter } from '@features/cart-items-counter';
import cn from 'clsx';
import dynamic from 'next/dynamic';
import React, {
  ComponentProps,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
} from 'react';

import { Illustration, Typography } from '@/shared/ui';

import styles from './navigation-bottom.module.scss';
import { NavigationBottomProps } from './navigation-bottom.types';
export const NavigationBottomEntityMobile = ({
  className,
  children,
}: NavigationBottomProps) => {
  const navClasses = cn(styles['container'], className);

  return (
    <nav className={navClasses}>
      <ul className={styles['tabs-container']}>{children}</ul>
    </nav>
  );
};
type NavigationItemProps = DetailedHTMLProps<
  HTMLAttributes<HTMLLIElement>,
  HTMLLIElement
> & {
  children?: ReactNode;
  topSlot: ReactNode;
  bottomSlot: ReactNode;
  withBadge?: boolean;
  isActive: boolean;
};

// eslint-disable-next-line react/display-name
NavigationBottomEntityMobile.Item = ({
  topSlot,
  withBadge,
  bottomSlot,
  className,
  isActive,
  ...props
}: NavigationItemProps) => {
  return (
    <li
      className={cn(
        styles['item'],
        { [styles['active']]: isActive },
        className,
      )}
      {...props}
    >
      <div className={styles['top-slot']}>
        {topSlot}
        {withBadge && <CartBadgeItemsCounter isMobile />}
      </div>
      {bottomSlot}
    </li>
  );
};

// eslint-disable-next-line react/display-name
NavigationBottomEntityMobile.Title = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Typography className={styles['bottom-slot--title']}>{children}</Typography>
  );
};

// eslint-disable-next-line react/display-name
NavigationBottomEntityMobile.Icon = ({
  icon,
}: {
  icon: ComponentProps<typeof Illustration>['name'];
}) => {
  return (
    <Illustration
      name={icon}
      spriteName={'icons'}
      spanTagClassName={styles['bottom-slot--icon']}
    />
  );
};
