import { PromocodeModalContent } from '@features/promocode-modal-content';
import React from 'react';

import { ModalWrapper } from '@/shared/ui/modal-wrapper';

import { PromocodeModalProps } from './types';

export const PromocodeModal = ({
  isOpened,
  close,
  isMobile,
}: PromocodeModalProps) => {
  return (
    <ModalWrapper close={close} isOpened={isOpened} isMobile={isMobile}>
      <PromocodeModalContent close={close} />
    </ModalWrapper>
  );
};
