import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';

import styles from '@/features/auth-modal-content/ui/styles.module.scss';
import { CHANGE_PASSWORD } from '@/shared/api/endpoints';
import usersApi from '@/shared/api/usersApi';
import {
  Button,
  Illustration,
  IllustrationModal,
  ModalContent,
  Typography,
} from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';
import { AuthInfo } from '@/store/authorization/types';
import { useStore } from '@/store/context';

import { validationSchema } from './validation';

export const ResetForm = observer(() => {
  const auth = useStore().authorization;

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const onSubmit = async () => {
    try {
      await usersApi.put<AuthInfo>(CHANGE_PASSWORD, {
        oldPassword: formik.values.oldPassword,
        newPassword: formik.values.password,
        confirmPassword: formik.values.confirmPassword,
      });
      auth.setStep(1);
    } catch (err: any) {
      formik.setFieldError('oldPassword', 'The old password is incorrect');
    }
  };

  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={
        <ModalContent.CloseButton onClick={() => auth.closeModal()} />
      }
    >
      {auth.step === 0 && (
        <>
          <IllustrationModal variant={'password-recovery'} />
          <ModalContent.Title
            title={'Password'}
            subtitle={'You can update your password'}
          />

          <ModalContent.Body>
            <>
              <div className={styles['inputs-container']}>
                <InputAuth
                  iconName={'lock-password'}
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange('oldPassword')}
                  onBlur={formik.handleBlur('oldPassword')}
                  onInput={() => formik.setFieldTouched('oldPassword')}
                  placeholder="Old password"
                  securityInput
                  errorMessage={
                    formik.touched.oldPassword ? formik.errors.oldPassword : ''
                  }
                />
                <InputAuth
                  iconName={'lock-password'}
                  value={formik.values.password}
                  onChange={formik.handleChange('password')}
                  onBlur={formik.handleBlur('password')}
                  onInput={() => formik.setFieldTouched('password')}
                  placeholder="Password"
                  securityInput
                  errorMessage={
                    formik.touched.password ? formik.errors.password : ''
                  }
                />
                <InputAuth
                  iconName={'lock-password'}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange('confirmPassword')}
                  onBlur={formik.handleBlur('confirmPassword')}
                  onInput={() => formik.setFieldTouched('confirmPassword')}
                  placeholder="Confirm password"
                  securityInput
                  errorMessage={
                    formik.touched.confirmPassword
                      ? formik.errors.confirmPassword
                      : ''
                  }
                />
                <div className={styles['forgot-container']}>
                  <Typography
                    className={styles['forgot-text']}
                    onClick={() => {
                      auth.openRecovery();
                    }}
                  >
                    Forgot password?
                  </Typography>
                </div>
              </div>
              <ModalContent.Footer>
                <Button
                  onClick={onSubmit}
                  variant="primary"
                  text="Change password"
                  width="full-w"
                  className={styles['bottom-container-button']}
                  disabled={!formik.isValid}
                />
                <Button
                  onClick={() => auth.closeModal()}
                  variant="secondary"
                  iconLeft={
                    <Illustration
                      style={{ color: '#6941C6' }}
                      spanTagClassName={styles['button-icon']}
                      name={'square-arrow-left'}
                      spriteName={'icons'}
                    />
                  }
                  text={'Back to settings'}
                  width="full-w"
                  className={styles['bottom-container-button']}
                />
              </ModalContent.Footer>
            </>
          </ModalContent.Body>
        </>
      )}
      {auth.step === 1 && (
        <>
          <IllustrationModal variant={'password-changed'} />
          <ModalContent.Title
            title={'Congratulations'}
            subtitle={'You have updated your password'}
          />
          <AdditionalInfoMobile variant={'success'}>
            Password change successful
          </AdditionalInfoMobile>
          <ModalContent.Footer>
            <BottomSheetUi.Button
              variant={'primary'}
              text={'Close'}
              onClick={() => auth.closeModal()}
            />
          </ModalContent.Footer>
        </>
      )}
    </ModalContent>
  );
});
