'use client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Badge } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './cart-badge-count.module.scss';

export const CartBadgeItemsCounter = observer(
  ({ isMobile }: { isMobile?: boolean }) => {
    const cart = useStore()?.cart;
    const totalAmount = cart?.totalSumm >= 0 ? cart?.totalSumm : 0;
    const badgeCount = cart?.totalQuantity;

    const badgeStyles = cn(
      styles['badge'],
      { [styles['mobile']]: isMobile },
      { [styles['one-number']]: badgeCount && badgeCount < 10 },
      { [styles['two-numbers']]: badgeCount && badgeCount >= 10 },
      { [styles['three-numbers']]: badgeCount && badgeCount > 99 },
    );

    return totalAmount > 0 ? (
      <Badge count={badgeCount} className={badgeStyles} isMobile={isMobile} />
    ) : null;
  },
);
