'use client';

import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';

export const FooterAccordionsWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const pathname = usePathname();

  const hideAccordion =
    pathname.includes('inventory') ||
    pathname.includes('cart') ||
    pathname.includes('categories') ||
    RegExp('^\\/[a-z]{2}\\/faq(\\/[a-z-]*)$').test(pathname);

  return !hideAccordion && children;
};
